<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('orderFilter')"
                  >
                    <CIcon size="lg" name="cil-filter" class="mr-2"/>
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
            <div class="col-9 text-right d-none">
              <CButton color="primary" square size="sm" disabled>Excel'e Aktar</CButton>
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="orders"
            :fields="fields"
            :items-per-page="10"
            :active-page="1"
            :loading="loading"
            hover
            pagination
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="openModalEvent('list', 'orderDetailList', lastItem, 'Sipariş Detay Listesi')"
            v-on:refresh="productionOrders"
          >
            <template #show_details="{item}">
              <td class="py-2">
                <CButton 
                  size="sm" 
                  color="info" 
                  @click="openModalEvent('list', 'orderDetailList', item, 'Sipariş Detay Listesi')">
                    Detay
                </CButton>
                <CButton 
                  class="m-1"
                  size="sm" 
                  color="warning" 
                  @click="openModalEvent('list', 'orderCheckList', item, item.id +' - '+item.productionCompany+' Adet Kontrol Listesi')">
                    Adet Kontrol
                </CButton>
              </td>
            </template>
            <template #createTime="{item}">
              <td> {{ dateFormat(item.createTime)}}</td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <ListModal
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :module="module"
      :form="form"
      :data="modalProps"
    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterProductionOrders"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>

<script>

import moment from 'moment';
import ListModal from '../components/listModal'
import FilterSidebar from '../components/filterSidebar'
import { prepareFilterParams } from "@/helpers/custom"

export default {
  name: 'ProductionOrders',
  components:{ ListModal, FilterSidebar },
  data() {
    return {
      modalTitle: '',
      actionType: '',
      module: 'production',
      form: 'orderDetailList',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      openSidebar: false,
      filterForm: 'orderFilter',
      filterParams: {},
      lastItem: {},

      activePage: 1,
      itemsPerPage: 10,
     
      fields: [
        {key: 'id', label: "ID",_style: 'font-size:12px'},
        {key: 'orderNo', label: 'Sipariş No',_style: 'font-size:12px'},
        {key: 'productionCompany', label: 'Üretici Firma',_style: 'font-size:12px'},
        {key: 'createTime', label: 'Tarih',_style: 'font-size:12px'},
        {key: 'amount', label: 'Toplam Adet',_style: 'font-size:12px'},
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        }
      ],
      filters: [{
        type: 'date',
        dataIndex: 'startTime',
        field: 'createTime',
        comparison: 'gt',
      },{
        type: 'date',
        dataIndex: 'endTime',
        field: 'endTime',
        comparison: 'lt',
      }],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
  },
  computed: {
    reloadParams() {
      return [
        this.activePage
      ]
    },
    orders(){
      return this.$store.getters.orders
    },
    loading(){
      return this.$store.getters.loading
    }
  },
  methods: {
    rowClicked(item) {
      this.lastItem = item
    },
    async productionOrders() {
      await this.$store.dispatch('productionOrder_list')
    },
    openModalEvent(actionType, form, data, title, desc){
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },
    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },
    dateFormat(date){
      return moment(date).format("DD.MM.YYYY HH:mm:ss")
    },
    async filterProductionOrders(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)

      await this.$store.dispatch('productionOrder_list', formData)

      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },
  },
  mounted() {
    this.productionOrders();
  }
}
</script>
<style>
input,
select {
  padding: 0.45em 0.5em;
  font-size: 100%;
  border: 1px solid #ccc;
  width: 100%;
}
</style>
